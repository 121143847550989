
import {mapGetters} from 'vuex'

export default {
  name: 'InputTel',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    mode: {
      type: String,
      default: 'international',
    },
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    inputOptions: {
      type: Object,
      default: () => {},
    },
    error: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Введите телефон',
    },
  },
  data() {
    const defaultDropdown = {
      showDialCodeInList: true,
      showDialCodeInSelection: false,
      showSearchBox: true,
      showFlags: true,
      ignoredCountries: [],
    }

    const defaultInput = {
      placeholder: this.placeholder,
      enabledCountryCode: true,
      showDialCode: true,
    }

    return {
      optionsDropdown: { ...defaultDropdown },
      defaultDropdown: { ...defaultDropdown },

      optionsInput: { ...defaultInput },
      defaultInput: { ...defaultInput },
      inputValue: '',
      inputValuePrev: '',
      isValid: false,
    }
  },
  computed: {
    ...mapGetters({
      getAddresses: 'checkout/getAddresses',
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
    }),
    onlyCountries() {
      return this.getCountriesList.map(item => item.value)
    },
    defaultCountry() {
      return this.getSelectedCountry.value
    },
  },
  watch: {
    placeholder() {
      this.optionsInput.placeholder = this.placeholder
    },
    inputValue(newValue) {
      this.$emit('input', newValue || '')
    },
  },
  methods: {
    onInputUpdate({ isValid, formatInternational, formattedNumber, phoneNumber }) {
      if (this.isValid && phoneNumber.length >= this.inputValuePrev?.length + 1) {
        this.$nextTick(() => {
          this.inputValue = this.inputValuePrev
        })
        return
      }

      this.inputValuePrev = phoneNumber
      this.inputValue = phoneNumber
      this.isValid = isValid

      this.$emit('onValidate', { isValid, international: formatInternational, number: formattedNumber })
    },
  },
}
